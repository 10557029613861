.search {
    @apply flex flex-col items-center justify-center text-center fixed top-0 left-0 w-full opacity-0;
    height: 50vh;
    pointer-events: none;
    transform: scale(0.75);
    transition: all .25s cubic-bezier(.2, 1, .3, 1);
    z-index: 1050;
  
    &.show {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1);
      transition: all .5s cubic-bezier(.2, 1, .3, 1);
    }
  }
  
  .search-input {
    border-bottom: 1px solid var(--foreground-divider);
    font-size: 7vw;
    line-height: 3rem;
    width: 75%;
    @apply m-0 rounded-none border-r-0 border-l-0 border-t-0 font-bold bg-transparent;
  
    &:focus {
      outline: none;
    }
  }
  
  .search-hint {
    width: 75%;
    @apply text-base text-right py-4 mx-auto text-hint font-bold;
  }
  
  .search-overlay {
    @apply fixed w-full bottom-0 left-0 opacity-0;
    height: 50vh;
  }
  