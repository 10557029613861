.vex-layout-ikaros {
  // Workaround: CSS calc() functions can't deal with values without a unit (e.g. 0 instead of 0px), when this is 0, SCSS or PostCSS make it a 0 instead of 0px, breaking the layout.
  --navigation-height: 0.00001px;

  &.vex-style-default {
    --toolbar-background: #21428f;
    /* #{$sidenav-background};*/
    --navigation-color: white;
    --toolbar-color: white;
    --background-app-bar: #dedfee;
  }
}


.mat-horizontal-content-container {
  padding: 0px !important;
  overflow: overlay;
}

