.mbsc-material.mbsc-textfield-wrapper {
  margin: 0em 0em !important;
  margin-bottom: 1.5em !important;
  box-sizing: border-box;
  width: -webkit-fill-available;
  width: -moz-available !important;
}

// .go-tabless {
.mbsc-focus {
  .mbsc-label {
    color: rgb(var(--color-primary));
  }

  .mbsc-material.mbsc-select-icon.mbsc-ltr {
    color: rgb(var(--color-primary));
  }

  .mbsc-textfield {
    border-color: rgb(var(--color-primary));
    border-width: 2px;
  }

  //icon color change
  .mbsc-material.mbsc-textfield-icon,
  .mbsc-material.mbsc-select-icon {
    color: rgb(var(--color-primary));
  }
}

//dropdown mobiscoll control border - radius
.mbsc-textfield-inner-box {
  // border-radius: 8px 8px 0 0;
  border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
}

.mbsc-ios.mbsc-textfield-box,
.mbsc-ios.mbsc-textfield-outline {
  border-radius: 0;
  border: 1px solid transparent;
  border-bottom: 1px solid lightgray;
}

.mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-box,
.mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-outline {
  background: rgba(0, 0, 0, 0.0392156863) !important;
}

.mbsc-ios.mbsc-textarea-inner {
  background: #0000000a;
}

.mbsc-ios.mbsc-textfield {
  background: #0000000a;
  // border-radius: 8px 8px 0 0 !important;
  border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
}


.mbsc-ios.mbsc-textfield-wrapper-outline,
.mbsc-ios.mbsc-textfield-wrapper-box {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mbsc-ios .mbsc-textfield-ripple-active {
  border-bottom: 1px rgba(var(--color-primary), 1) !important;
}

.mbsc-ios.mbsc-focus {
  .mbsc-label {
    color: rgba(var(--color-primary), 1) !important;
  }

  &.mbsc-ios.mbsc-textfield-box,
  .mbsc-ios.mbsc-textfield-outline {
    border-bottom: 2px solid rgba(var(--color-primary), 1) !important;
  }
}

.mbsc-ios .mbsc-hover .mbsc-textfield {
  border-bottom: 2px solid #523f69de !important;
}

input {
  caret-color: rgba(var(--color-primary), 1) !important;
}

//mobiscroll control border - radius
.mbsc-material .mbsc-select {
  // border-radius: 8px 8px 0 0;
  border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
}

.mbsc-ios.mbsc-label.mbsc-error {
  color: #de3226;
}

//search text box have 2
.go-fillter-box .mbsc-material .mbsc-select {
  background: transparent !important;
}

.go-fillter-box .mbsc-ios .mbsc-select {
  background: transparent !important;
}

// }

//LEFT RIGHT SPACE
.mbsc-ios.mbsc-textfield-wrapper-outline,
.mbsc-ios.mbsc-textfield-wrapper-box {
  margin: 0em 0em;
  margin-bottom: 1.5em;
  width: -webkit-fill-available;
  width: -moz-available !important;
}

.mbsc-ios.mbsc-label,
.mbsc-ios.mbsc-textfield {
  color: rgba(0, 0, 0, 0.6);
}

.mbsc-material.mbsc-label,
.mbsc-material.mbsc-textfield {
  color: rgba(0, 0, 0, 0.6);
}

//dark theme
.vex-style-dark .mat-form-field-label,
.vex-style-dark .mat-hint {
  color: #ffffffb3;
}

.vex-style-dark .mbsc-select {
  background: transparent !important;
}

.vex-style-dark .mbsc-textfield-inner {
  background: #ffffff1a !important;
}

.vex-style-dark .mbsc-label {
  color: #ffffffb3;
}

.vex-style-dark .mbsc-textfield {
  color: #ffffff;
}

// .vex-style-dark .mbsc-material .mbsc-hover .mbsc-textfield {
//   border-bottom: 2px solid #ffffff;
// }

.vex-style-dark select option {
  background-color: #1a202e;
  color: #fff;
}

//fillter dropdown 
.mbsc-select-filter {
  margin-bottom: 0px !important;
}

//group dropdown
.mbsc-select-group {
  width: -webkit-fill-available;
  width: -moz-available !important;
}

.mbsc-material.mbsc-switch .mbsc-switch-handle {
  margin: -10px !important;
}

.mbsc-checked.mbsc-ios .mbsc-switch-handle {
  left: 70% !important;
}

.mbsc-ios {
  .mbsc-switch-track {
    border-radius: var(--border-radius) !important;
    background: #fff;
  }

  .mbsc-switch-handle {
    border-radius: var(--border-radius) !important;
    left: 30% !important;
  }
}

.mbsc-material {
  .mbsc-switch-track {
    border-radius: 0.5rem !important;
    // background: transparent;
  }

  .mbsc-ios .mbsc-switch-handle {
    border-radius: 0.5rem !important;
  }
}

.mbsc-material.mbsc-switch input:checked+.mbsc-switch-track {
  background-color: #4caf50 !important;
}

.mbsc-material.mbsc-switch-track.mbsc-switch-primary.mbsc-checked {
  background: #4caf50 !important;
}

.mbsc-material.mbsc-switch input:checked+.mbsc-switch-track .mbsc-switch-handle {
  background: #fff !important;
}

.mbsc-material-dark .mbsc-switch-track {
  background: #c4c4c4 !important;
}

.mbsc-material-dark.mbsc-switch .mbsc-switch-handle {
  background: #fff !important;
}

.active .mbsc-material.mbsc-switch-track.mbsc-switch-primary.mbsc-checked {
  background: #4caf50 !important;
}

.mbsc-ios .mbsc-switch-handle {
  top: 15px !important;
  width: 1.5em !important;
  height: 1.4em !important;

}

.mbsc-checked.mbsc-ios .mbsc-switch-handle {
  left: 75% !important;
}

// .active .mbsc-ios.mbsc-switch input:checked+.mbsc-switch-track .mbsc-switch-handle {
//   background: none !important;
// }


//hint
// .hint-input.mbsc-material.mbsc-textfield-wrapper {
//   margin-bottom: 0px !important;
// }

.hint {
  margin-bottom: 0.5rem;
  font-size: smaller;
}


//toggle
.toggle-position {
  position: absolute !important;
  // right: 25px;
  right: 0px;
  z-index: 9 !important;
}

.toggle-position-dropdown {
  right: 10px !important;
}

//material
// .active .mbsc-material.mbsc-switch input:checked+.mbsc-switch-track .mbsc-switch-handle {
//   background: rgb(76, 175, 80) !important;
// }

// .active .mbsc-material.mbsc-switch-track.mbsc-switch-primary.mbsc-checked {
//   background: rgba(76, 175, 80, 0.3) !important;
// }

.incomplete .mbsc-material.mbsc-switch .mbsc-switch-handle {
  background: #fff !important;
}

.incomplete .mbsc-material.mbsc-switch-track.mbsc-switch-primary {
  background: rgba(242, 147, 84, 1) !important;
}

.inactive .mbsc-material.mbsc-switch .mbsc-switch-handle {
  background: #fff !important;
}

.inactive .mbsc-material.mbsc-switch-track.mbsc-switch-primary {
  background: rgba(244, 67, 54, 1) !important;
}

.incomplete .mbsc-material.mbsc-switch input:checked+.mbsc-switch-track {
  /* background-color: #4caf50 !important; */
  background: rgb(242, 147, 84) !important;
}

//ios
.mbsc-ios .mbsc-switch-track {
  right: -0.5em !important;
  height: 1.55em !important;
  padding: 0;
  margin-top: -13px !important;
  box-sizing: content-box;
}



// .active .mbsc-ios.mbsc-switch input:checked+.mbsc-switch-track .mbsc-switch-handle {
//   background: rgb(76, 175, 80) !important;
// }

// .active .mbsc-ios.mbsc-switch-track.mbsc-switch-primary {
//   background: rgba(76, 175, 80, 0.3) !important;
// }

.inactive .mbsc-ios.mbsc-switch .mbsc-switch-handle {
  background: #fff !important;
}

.inactive .mbsc-ios .mbsc-switch-track:after {
  background: rgb(244, 67, 54, 1) !important;
}

.inactive .mbsc-ios .mbsc-switch-track {
  background: rgb(244, 67, 54, 1) !important;
}

.incomplete .mbsc-ios.mbsc-switch .mbsc-switch-handle {
  background: #fff !important;
}

.incomplete .mbsc-ios .mbsc-switch-track:after {
  background: rgba(242, 147, 84, 1) !important;
}

.incomplete .mbsc-ios .mbsc-switch-track {
  background: rgba(242, 147, 84, 1) !important;
}


.mbsc-ios.mbsc-switch input:checked+.mbsc-switch-track {
  background: #4cd764 !important;
  border-color: #4cd764 !important;
}

.integration .mbsc-material.mbsc-ltr.mbsc-switch {
  padding: 0.9em 4em 0.9375em 1em !important;
}

.integration .mbsc-ios.mbsc-ltr.mbsc-switch {
  padding: 0.9em 4em 0.9375em 1em !important;
}

.mbsc-material.mbsc-switch-right {
  padding: 2.5em 4em 0.9375em 1em !important;
}

.mbsc-ios.mbsc-switch-right {
  padding: 2.7em 4em 0.9375em 1em !important;
}

.mbsc-ios.mbsc-form-control-wrapper {
  background: transparent !important;
  border: 0px !important;
  width: 100% !important;
}

.mbsc-material.mbsc-form-control-wrapper {
  width: 100% !important;
}



.mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:after,
.mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:before {
  right: initial !important;
  left: initial !important;
}


//filtter input box - dropdown - getstart 
.mbsc-select-filter.mbsc-material.mbsc-textfield-wrapper {
  margin-bottom: 0px !important;
}

.mbsc-select-filter.mbsc-ios.mbsc-textfield-wrapper {
  margin-bottom: 0px !important;
}

.mbsc-material.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected {
  color: rgb(var(--color-primary))
}

.mbsc-ios.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected {
  color: rgb(var(--color-primary))
}

.mbsc-material.mbsc-wheel-checkmark::after {
  border-color: rgb(var(--color-primary))
}

.mbsc-ios.mbsc-wheel-checkmark::after {
  border-color: rgb(var(--color-primary))
}

.go-fillter-box .mbsc-ios .mbsc-select {
  background-color: transparent !important;
}


//app-calender-hours --mbsc-time  
.mbsc-datepicker-tab {
  display: block !important;
}

.vex-style-dark {

  .mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-box,
  .mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-outline {
    background: rgba(255, 255, 255, 0.1019607843) !important;
  }
}

//mobiscroll - dark mode custom background
.mbsc-mobiscroll-dark .mbsc-fr-w {
  background: #1a202e !important;
  color: #ffffff;

}

.mbsc-fr-w {
  border-radius: var(--border-radius) !important;
}

.mbsc-material-dark.mbsc-popup-body {
  background: #1a202e !important;
}

.mbsc-IOS-dark.mbsc-popup-body {
  background: #1a202e !important;
}

.mbsc-ios-dark.mbsc-picker .mbsc-popup-arrow,
.mbsc-ios-dark.mbsc-picker .mbsc-popup-body {
  background: #1a202e !important;
}

.mbsc-ios-dark.mbsc-scroller {
  background: #1a202e !important;
}

//searchable dropdown with the transparent color
.mbsc-textfield.mbsc-textfield-tags.mbsc-textarea {
  background: transparent !important;
}

.mbsc-ios-dark.mbsc-event-day.mbsc-list-header {
  background: #000000 !important;
}

.mbsc-ios-dark.mbsc-event.mbsc-list-item {
  background: #1a202e !important;
}

.mbsc-ios-dark .mbsc-event-list {
  background: #000000 !important;
}

// .vex-style-dark {
//   .mbsc-material.mbsc-textfield {
//     background-color: #ffffff1a !important;
//   }
//   .mbsc-ios.mbsc-textfield {
//     background-color: #ffffff1a !important;
//   }
// }

.mbsc-material.mbsc-popup-button.mbsc-font {
  border-radius: var(--border-radius) !important;
}

// .mbsc-ios.mbsc-popup-button.mbsc-font {
//   border-radius: var(--border-radius) !important;
// }

//dropdown - extra height
.mbsc-material.mbsc-textarea-inner.mbsc-textfield-inner-stacked,
.mbsc-material.mbsc-textarea-inner.mbsc-textfield-inner-floating {
  // padding-top: 1.25em;
  // padding-top: 1.00em;
  padding-top: 1.4em;

}

.mbsc-material.mbsc-popup-body {
  border-radius: var(--border-radius) !important;
}

.mbsc-ios.mbsc-popup-body {
  border-radius: var(--border-radius) !important;
}

.mbsc-mobiscroll .mbsc-fr-btn-cont {
  padding: 0 1em 0.5em 1em !important;
}

.endIconRemove .mbsc-material.mbsc-select-icon-right.mbsc-ltr {
  display: none;
}

.endIconRemove .mbsc-ios.mbsc-select-icon-right.mbsc-ltr {
  display: none;
}

// .mbsc-ios.mbsc-popup-buttons{
//   height: 0px !important;
// }

.mbsc-material .mbsc-fr-btn-cont {
  padding: 0 1em 0.5em 0.5em !important;
}

// .mbsc-ios .mbsc-fr-btn-cont {
//   padding: 0 1em 0.5em 0.5em !important;
// }