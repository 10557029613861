 //profile page - change the profile headline 
 .is-mobile {
   .top-height {
     height: 3rem !important;
     width: 3rem !important;
     top: 0.5rem !important;
   }

   .headline-text,
   h2 {
     font: 500 18px / 26px "Poppins", sans-serif;
     padding-left: 60px !important;
   }
 }

 //Profile page - dark mode
 .vex-style-dark .mat-tab-links {
   background-color: rgba(var(--background-foreground-rgb), var(--tw-bg-opacity));
 }

 .vex-style-dark .mat-tab-link {
   color: white !important;
 }


 //get started page - set up hours, company-credential
 .input-hours {
   .mbsc-material.mbsc-textfield-wrapper {
     margin-bottom: 0px !important;
   }

   .mbsc-ios.mbsc-textfield-wrapper {
     margin-bottom: 0px !important;
   }
 }

 .input-day-hours {
   margin-bottom: 20px !important;
 }

 .input-box-bottom {
   .margin-bottom-0.mbsc-material.mbsc-textfield-wrapper {
     margin-bottom: 0px !important;
   }

   .margin-bottom-0.mbsc-ios.mbsc-textfield-wrapper {
     margin-bottom: 0px !important;
   }

   .margin-bottom-0.mbsc-ios.mbsc-material.mbsc-textfield-wrapper {
     margin-bottom: 0px !important;
   }

   .margin-bottom-0.mbsc-ios.mbsc-ios.mbsc-textfield-wrapper {
     margin-bottom: 0px !important;
   }

   .margin-bottom-0 .mbsc-material.mbsc-textfield-wrapper {
     margin-bottom: 0px !important;
   }

   .margin-bottom-0 .mbsc-ios.mbsc-textfield-wrapper {
    margin-bottom: 0px !important;
  }
 }

 //level pricing 
 .level-style .mat-step-header {
   padding-left: 0px;
 }

 .level-style .mat-vertical-content {
   padding-left: 0px;
 }


 .sub-wizard .mat-step-header {
   padding-left: 0px;
 }

 .sub-wizard .mat-vertical-content {
   padding-left: 0px;
 }

 .sub-wizard .mat-vertical-content-container {
   margin-left: 12px;
 }



 .input-day-hours {
   .mbsc-material.mbsc-textfield-wrapper {
     margin: 0em 0em !important;
     margin-bottom: 0 !important;
   }

   .mbsc-ios.mbsc-textfield-wrapper {
     margin: 0em 0em !important;
     margin-bottom: 0 !important;
   }
 }