@media (max-width: 1280px) {
    .mat-header-row .mat-table-sticky {
        top: 62px !important;
    }

    .header {
        position: sticky !important;
        top: -1px !important;
        z-index: 2;
    }
}

@media (min-width: 1280px) {
    .header {
        position: sticky !important;
        top: 0px !important;
        z-index: 2;
    }
}

.font-small {
    font-size: smaller !important;
}

.mat-mini-fab-round {
    border-radius: var(--border-radius) !important;
}


tr.mat-header-row {
    height: 40px !important;
    background-color: #e2e2e2;
}

.vex-style-dark tr.mat-header-row {
    height: 40px !important;
    background-color: #000000;
}

tr.mat-row,
tr.mat-footer-row {
    height: 60px !important;
}

.imgSquer {
    height: 40px !important;
    min-width: 40px !important;
    border-radius: 0.5rem !important;
}

.vex-page-layout-header {
    background-color: transparent !important
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
    padding-left: 15px;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
    padding-right: 15px;
}


//add side bar table 
.mat-tab-body.mat-tab-body-active {
    position: relative;
    overflow-x: overlay !important;
    overflow-y: overlay !important;
    z-index: 1;
    flex-grow: 1;
}

.mat-sort-header.mat-header-cell {
    top: 0px !important;
}

//add side bar table 
th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
    padding: 0px !important;
}

.mat-footer-cell {
    padding-left: 12px !important;
}


table tfoot {
    position: sticky;
}


// table {
//     width: 100%;
//     table-layout: fixed;
// }

// th,
// td {
//     overflow: hidden;
//     width: auto;
//     text-overflow: ellipsis;
//     white-space: nowrap;
// }

.table-bottom-rounder.mat-table{
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
    overflow: hidden !important;
}
.table-bottom-rounder .mat-table{
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
    overflow: hidden !important;
}
 