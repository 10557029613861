.vex-layout-apollo {
  .has-fixed-footer {
    .content {
      --toolbar-height: 57px;
      min-height: calc(100% - var(--toolbar-height)) !important;
      margin-bottom: var(--footer-height);
    }
  }
}

.mbsc-fr-btn{
  border-radius: var(--border-radius) !important;
}