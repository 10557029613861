:root .pt-6 {
    padding: 0.755rem !important;
}

.mat-tab-label {
    padding: 0 12px !important;
    min-width: 90px !important;
}

.sidebar-inner-padding {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.mat-expansion-panel.mat-expansion-panel.ng-tns-c350-37.ng-tns-c356-7.mat-expanded.mat-expansion-panel-spacing.ng-star-inserted {
    box-shadow: none !important;
}

.mat-expansion-panel-body {
    padding: 0 5px 5px !important;
}

.sidebar .position-right .open {
    background: #d8d8f7 !important;
}

// .sidebar-access .mat-tab-body {
//     padding: 10px !important
// }

.sidebar-access .mat-tab-body {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 10px !important;
}

.card-icon-color {
    color: rgb(var(--color-primary))
}

.card-btn .mat-button {
    min-width: 10.5rem !important;
}

.card-btn .mat-focus-indicator {
    min-width: 10.5rem !important;
}

.sidebar .card-btn.btn-width .mat-focus-indicator {
    min-width: 10.5rem !important;
}

.card-btn.btn-width .mat-focus-indicator {
    min-width: 15.5rem !important;
}

.mat-expansion-panel-body {
    padding-bottom: 10px !important;
}

.mat-expansion-panel-header.mat-expanded {
    height: 48px !important;
}

.mat-expanded .header-size {
    font-weight: 500 !important;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
    top: 0px !important;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
    top: 0px !important;
}

.mat-tab-body {
    border-radius: 0.5rem;
}

// .align-center {
//     align-self: center;
// }

//side bar control manage under the 400 resolutions
@media (max-width: 400px) {
    .sidebar.position-right.open {
        width: -webkit-fill-available !important;
    }
}



.bg-sidebar {
    background: rgba(var(--color-primary), 0.1);
    height: calc(100vh + 500px);
}


//user-user page
.sidebar-table-height{
    height: calc(100vh - 135px);
}
.sidebar-table-height .header{
    position: relative !important;
    top: 0px !important;
    z-index: 2;
}
.sidebar-table-height .mat-table-sticky {
    position: relative !important;
}

//if only 1 card in the side bar then rounded cornor
.first-mat-card.mat-accordion .mat-expansion-panel:first-of-type {
    border-radius: var(--border-radius) !important;
    overflow: hidden;
}
 