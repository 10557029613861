:root .overflow-auto {
  overflow: hidden !important;
  overflow-x: overlay !important;
  overflow-y: clip !important;
}

//scroll
:root .overflow-scroll {
  overflow: overlay !important;
}

.image-Square-round {
  border-radius: 0.5rem !important;
}

//tab
.mat-tab-list {
  background-color: white !important;
}

.mat-tab-labels {
  background-color: var(--background-foreground);
}

.cdk-overlay-pane {
  max-width: 90vw !important;
}

//expanded
.mat-expansion-indicator::after {
  border-color: rgb(var(--color-primary)) !important;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  margin-right: 0px !important;
}

.round-button {
  height: 50px !important;
  border-radius: 0.5rem !important;
}

.datePicker {
  width: -webkit-fill-available !important
}

//primary background
.primary-background {
  background-color: rgb(var(--color-primary), 0.1);
}

.primary-color {
  color: rgb(var(--color-primary));
}

.current-background {
  background-color: rgb(var(--color-primary));
}

.current-color {
  color: #fff;
}

.w-100 {
  width: 100% !important;
}

.app-contact .w-55 {
  width: 40% !important;
}

//mat-pannel first and last pannel
.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: var(--border-radius) !important;
  border-top-left-radius: var(--border-radius) !important;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
  margin-bottom: 10px !important;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75.5rem;
}

.width-webkit-fill {
  width: -webkit-fill-available !important;
  width: -moz-available !important;
}

//padding , margin, align
.margin-top-0 {
  margin-top: 0px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.pr-pl-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pr-pl-08 {
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between !important;
}

.align-center {
  align-items: center;
}


//extra
.my-button.mbsc-btn {
  background: #430095;
  color: #fff;
  text-transform: uppercase;
}

//material-error
.mat-error {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.9166666667em);
}

.mat-dialog-container {
  // border-radius: 0.5rem !important;
  border-radius: var(--border-radius) !important;
}

.font-weight-600 {
  font-weight: 550
}

.font-weight-500 {
  font-weight: 500
}

.table-header-radius {
  // border-top-left-radius: 8px;
  // border-top-right-radius: 8px;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.h-20 {
  height: 5rem !important;
}

.w-20 {
  width: 5rem !important;
}

.image-dropdown {
  width: 35px;
  height: 35px;
}

//scroll hiding when open the
.vex-scrollblock .page-container {
  position: relative !important;
  display: block !important;

  .sidenav-content {
    overflow-y: hidden !important;
  }
}





//side-bar tab fix header
.fixed-header {
  position: sticky !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
}

::ng-deep.mat-fixed-header .mat-tab-header {
  position: sticky !important;
  top: 64px !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 99 !important;
}

.img-width {
  min-width: 3.5rem !important;
  padding-left: 0px;
  padding-right: 0px;
}

.h-35 {
  height: 3.5rem !important;
}

.w-35 {
  width: 3.5rem !important;
}


//printing-calender
.md-custom-event .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-header {
  background: var(--background-app-bar) !important;
}

.md-custom-event .mbsc-ios.mbsc-schedule-date-header {
  background-color: var(--background-app-bar) !important;
  height: 40px !important;
}

.md-custom-event .mbsc-material.mbsc-schedule-date-header {
  background-color: #e2e2e2;
}

.md-custom-event {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.md-custom-event .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-wrapper {
  // height: 4rem;
  background: var(--background-app-bar) !important;
  height: 52px !important;
  align-items: center;
  display: block;
  align-self: center;
}

.md-custom-event .mbsc-calendar-header .mbsc-ios {
  margin-top: 2px;
}

.md-custom-event .mbsc-calendar-header .mbsc-material {
  margin-top: 2px;
}

.md-custom-event .mbsc-ios.mbsc-schedule-date-header-text {
  padding: 0.9em;
}

.md-custom-event .mbsc-event {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

//end



.content-sidebar .mat-tab-body-content {
  padding: 10px !important;
  background: white;
  // border-radius: 0.5rem;
  border-radius: var(--border-radius);
}

// .mat-drawer-content{
//   height: 100vh !important;
// }


.position-relative {
  position: relative;
}





//calender css
.mbsc-calendar-controls {
  background-color: var(--background-app-bar) !important;
  padding-right: 10px !important;
}

.mbsc-event-day {
  background-color: var(--background-app-bar) !important;
}

.mbsc-ios .mbsc-event-list {
  padding: 0 !important;
}


.flex-end {
  justify-content: flex-end;
  display: flex;
}

.cycle .mbsc-calendar-controls {
  display: none;
}


.statments .mat-tab-body-content {
  // box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: var(--border-radius) !important;
}

.text-bar {
  color: #b6b6c3 !important;
}

.mat-bottom-sheet-container {
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  margin: 24px;
  max-width: 33vw;
  min-width: 344px;
  padding: 14px 16px;
  min-height: 48px;
  transform-origin: center;
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}


.bottom-sheet .mat-button-base {
  color: rgba(var(--color-accent), 1) !important;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.d-flex-align-center {
  display: flex;
  align-items: center;
}

.mbsc-mobiscroll.mbsc-fr-center .mbsc-fr-w,
.mbsc-mobiscroll.mbsc-fr-bubble .mbsc-fr-w {
  border-radius: 0.25em;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}


.mat-dialog-actions.mail {
  padding: 26px 0 !important;
}


select option {
  background: #fff;
}

//calender header
.mbsc-calendar-controls {
  display: flex !important;
  justify-content: space-between !important;
}

.mbsc-search .mbsc-calendar-controls {
  display: block !important;
  padding-right: 0px !important;
}


//Location-edit - brand edit popup - set fixed tab in popup
.brand-setting .mat-tab-header {
  position: sticky;
  top: 0px;
  z-index: 9999;
}

.brand-setting .sidebar-access .mat-tab-body {
  padding: 0px !important;
}

// .brand-setting.mat-dialog-content {
//   padding: 15px !important;
// }


//Scroll added when popup open - hide this
.cdk-global-scrollblock {
  overflow-y: hidden !important;
}


.title,
h3 {
  font: 600 18px / 26px "Poppins", sans-serif !important;
  font: 600 18px / 26px var(--font) !important;
}



//badge text image color
.circlesNewActive {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(76 175 80 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(76, 175, 80, 0.1);
  --tw-text-opacity: 1;
  color: rgb(76 175 80 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

// :root .hover\:bg-hover:hover .circlesNewActive {
//   border: 2px solid rgb(76 175 80 / var(--tw-text-opacity));
// }

.circlesNewInActive {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(244 67 54 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(244, 67, 54, 0.1);
  --tw-text-opacity: 1;
  color: rgb(244 67 54 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

// :root .hover\:bg-hover:hover .circlesNewInActive {
//   border: 2px solid rgb(244 67 54 / var(--tw-text-opacity));
// }

.circlesNewAttention {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(244 67 54 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(244, 67, 54, 0.1);
  --tw-text-opacity: 1;
  color: rgb(242 147 84 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

// :root .hover\:bg-hover:hover .circlesNewAttention {
//   border: 2px solid rgb(242 147 84 / var(--tw-text-opacity));
// }

.circlesNewCompleted {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  //border: 2px solid rgb(255 152 0 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(242, 147, 84, 0.1);
  --tw-text-opacity: 1;
  color: rgb(255 152 0 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

// :root .hover\:bg-hover:hover .circlesNewCompleted {
//   border: 2px solid rgb(255 152 0 / var(--tw-text-opacity));
// }

.circlesNewFailed {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(244 67 54 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(244, 67, 54, 0.1);
  --tw-text-opacity: 1;
  color: rgb(244 67 54 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

// :root .hover\:bg-hover:hover .circlesNewFailed {
//   border: 2px solid rgb(244 67 54 / var(--tw-text-opacity));
// }

//order status related image circle


.circlesOrderPending {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(244 67 54 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(244, 67, 54, 0.1);
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

.circlesDetailFuture {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(76 175 80 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(var(--color-primary), 0.1);
  --tw-text-opacity: 1;
  color: rgba(var(--color-primary), 1);
  border-radius: var(--border-radius) !important;
}

.circlesDetailForLater {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(76 175 80 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(var(--color-primary), 0.1);
  --tw-text-opacity: 1;
  color: rgba(var(--color-primary), 1);
  border-radius: var(--border-radius) !important;
}

.circlesDetailPending {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(76 175 80 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(242, 147, 84, 0.1);
  --tw-text-opacity: 1;
  color: rgb(242 147 84 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

.circlesDetailActive {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(76 175 80 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(242, 147, 84, 0.1);
  --tw-text-opacity: 1;
  color: rgb(242 147 84 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

.circlesDetailCompleted {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(76 175 80 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(76, 175, 80, 0.1);
  --tw-text-opacity: 1;
  color: rgb(76 175 80 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

.circlesDetailCanceled {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(76 175 80 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(76, 175, 80, 0.1);
  --tw-text-opacity: 1;
  color: rgb(76 175 80 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

.circlesDetailFailed {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(244 67 54 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(244, 67, 54, 0.1);
  --tw-text-opacity: 1;
  color: rgb(244 67 54 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

:root .hover\:bg-hover:hover .circlesOrderPending {
  border: 2px solid rgb(0 0 0 / var(--tw-text-opacity));
}

.circlesOrderActive {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(244 67 54 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(244, 67, 54, 0.1);
  --tw-text-opacity: 1;
  color: rgb(242 147 84 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

:root .hover\:bg-hover:hover .circlesOrderActive {
  border: 2px solid rgb(242 147 84 / var(--tw-text-opacity));
}

.circlesOrderCompleted {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(244 67 54 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(244, 67, 54, 0.1);
  --tw-text-opacity: 1;
  color: rgb(76 175 80 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

:root .hover\:bg-hover:hover .circlesOrderCompleted {
  border: 2px solid rgb(76 175 80 / var(--tw-text-opacity));
}

.circlesOrderFailed {
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  // border: 2px solid rgb(244 67 54 / var(--tw-text-opacity));
  text-align: center;
  line-height: 40px;
  background-color: rgba(244, 67, 54, 0.1);
  --tw-text-opacity: 1;
  color: rgb(244 67 54 / var(--tw-text-opacity));
  border-radius: var(--border-radius) !important;
}

:root .hover\:bg-hover:hover .circlesOrderFailed {
  border: 2px solid rgb(244 67 54 / var(--tw-text-opacity));
}

//open side bar but white background comes
.vex-scrollblock .content.content-padding {
  height: 100vh !important;
}



.app-calender .app-calender-right .mbsc-textfield-inner-box {
  // border-radius: 0px 8px 0 0;
  border-radius: 0 var(--border-radius) 0 0 !important;
}

.app-calender .app-calender-left .mbsc-textfield-inner-box {
  // border-radius: 8px 0px 0 0;

  border-radius: var(--border-radius) 0 0 0 !important;
}

.app-calender .app-calender-right .mbsc-ios.mbsc-textfield {
  // border-radius: 0px 8px 0 0 !important;
  border-radius: 0 var(--border-radius) 0 0 !important;
}

.app-calender .app-calender-left .mbsc-ios.mbsc-textfield {
  border-radius: 8px 0px 0 0 !important;
  border-radius: var(--border-radius) 0 0 0 !important;

}

.app-contact .app-contact-right .mbsc-textfield-inner-box {
  border-radius: 0 var(--border-radius) 0 0 !important;
}

.app-contact .app-contact-left .mbsc-textfield-inner-box {
  border-radius: var(--border-radius) 0 0 0 !important;
}

.app-contact .app-contact-right .mbsc-ios.mbsc-textfield {
  border-radius: 0 var(--border-radius) 0 0 !important;
}

.app-contact .app-contact-left .mbsc-ios.mbsc-textfield {
  border-radius: 8px 0px 0 0 !important;
  border-radius: var(--border-radius) 0 0 0 !important;
}




.h-chat-custom .h-channel .h-header {
  border: 0.1px solid #000 !important;
}


.app-calender .mbsc-icon {
  height: 40px;
  width: 60px;
  z-index: 99999999999999;
}

.app-calender .mbsc-material.mbsc-textfield-icon-box-right {
  right: 0px !important;
}

.app-calender .mbsc-ios.mbsc-textfield-icon-right {
  right: 0px !important;
}

.get-started-bottom {
  padding-bottom: var(--padding-gutter-table);
  padding-left: var(--padding-gutter-table);
  padding-right: var(--padding-gutter-table);
}



.special-hours-calender .mbsc-ios .mbsc-label {
  position: absolute;
}

.special-hours-calender .mbsc-material .mbsc-label {
  position: absolute;
}

.special-hours-calender .mbsc-ios .mbsc-ltr .mbsc-textfield {
  height: 3.5em;
  padding-top: 1.25em;
}

.position-absolute {
  position: absolute;
}



//material

.mbsc-mobiscroll-dark .mbsc-fr-hdr,
.mbsc-mobiscroll-dark .mbsc-fr-btn {
  color: rgb(var(--color-primary)) !important;
  border-radius: var(--border-radius) !important;
}

.mbsc-mobiscroll .mbsc-fr-hdr,
.mbsc-mobiscroll .mbsc-fr-btn {
  color: rgb(var(--color-primary)) !important;
  border-radius: var(--border-radius) !important;
}

.mbsc-mobiscroll-dark .mbsc-fr-arr {
  background: #1a202e !important;
}

@media only screen and (max-width: 767px) {
  .header-size.custom-col-img.ng-tns-c541-118 {
    margin-bottom: 15px;
  }
}


//ios
.mbsc-ios .mbsc-fr-btn {
  color: rgb(var(--color-primary)) !important;
}

.mbsc-ios.mbsc-button-flat {
  color: rgb(var(--color-primary)) !important;
}

.mbsc-ios.mbsc-scroller-wheel-item.mbsc-hover {
  background: rgb(var(--color-primary), 15%) !important;
}

.mbsc-material.mbsc-scroller-wheel-item.mbsc-hover {
  background: rgb(var(--color-primary), 15%) !important;
}


//ios - dark mode
.mbsc-ios-dark.mbsc-button-flat {
  color: rgb(var(--color-primary)) !important;
}

.mbsc-ios-dark.mbsc-button-standard {
  color: rgb(var(--color-primary)) !important;
}

.mbsc-ios.mbsc-ltr.mbsc-switch {
  border: none !important;
}

.mbsc-ios-dark.mbsc-switch input:checked+.mbsc-switch-track {
  border-color: none !important;
  background: rgb(var(--color-primary)) !important;
}

.mbsc-ios-dark .mbsc-switch-track {
  border-color: inherit !important;
}

//Dark - mode - ios hover color changes
.mbsc-ios-dark.mbsc-scroller-wheel-item.mbsc-hover {
  background: rgb(var(--color-primary), 15%) !important;
}

.mbsc-material-dark.mbsc-scroller-wheel-item.mbsc-hover {
  background: rgb(var(--color-primary), 15%) !important;
}

.mbsc-ios-dark.mbsc-textfield-outline.mbsc-error,
.mbsc-ios-dark.mbsc-textfield-box.mbsc-error {
  border-color: transparent !important;
  border-bottom-color: red !important;
}

mbsc-switch.ng-tns-c521-51.mbsc-font.mbsc-form-control-wrapper.mbsc-hb.mbsc-ios.mbsc-ios-dark.mbsc-ltr.mbsc-switch.mbsc-switch-right.ng-star-inserted {
  border: none !important;
}

mbsc-switch.toggle-position.mbsc-font.mbsc-form-control-wrapper.mbsc-hb.mbsc-ios.mbsc-ios-dark.mbsc-ltr.mbsc-switch.mbsc-switch-right.ng-dirty.ng-invalid.ng-touched {
  border: none;
}

mat-tab-header.mat-tab-header.mat-tab-header-pagination-controls-enabled {
  background-color: var(--background-app-bar) !important;
}

mbsc-switch.toggle-position.mbsc-font.mbsc-form-control-wrapper.mbsc-hb.mbsc-ios.mbsc-ios-dark.mbsc-ltr.mbsc-switch.mbsc-switch-right.ng-untouched.ng-pristine.ng-valid {
  border: none;
}

.mbsc-ios-dark.mbsc-selected .mbsc-calendar-cell-text {
  background: rgb(var(--color-primary)) !important;
  border-color: rgb(var(--color-primary)) !important;
}

.mbsc-ios-dark.mbsc-button-standard {
  color: rgb(var(--color-primary)) !important;
}

.mbsc-mobiscroll-dark .mbsc-fr-hdr,
.mbsc-mobiscroll-dark .mbsc-fr-btn {
  color: rgb(var(--color-primary)) !important;
}

.mbsc-ios .mbsc-fr-btn {
  color: rgb(var(--color-primary)) !important;
}

.mbsc-mobiscroll .mbsc-fr-hdr,
.mbsc-mobiscroll .mbsc-fr-btn {
  color: rgb(var(--color-primary)) !important;
}

mbsc-switch.ng-tns-c521-51.mbsc-font.mbsc-form-control-wrapper.mbsc-hb.mbsc-ios.mbsc-ios-dark.mbsc-ltr.mbsc-switch.mbsc-switch-right.ng-star-inserted {
  border: none !important;
}

@media only screen and (max-width: 767px) {
  .header-size.custom-col-img.ng-tns-c541-118 {
    margin-bottom: 15px;
  }
}

.material-icons-brandImage {
  font-size: 53px !important;
  padding-left: 5px !important;

  height: 57px !important;
  min-width: 65px !important;
  border-radius: 0.5rem !important;
  border: 1px solid lightgray !important;
  align-items: center;
  justify-content: center;
}

.brandImage-light-color {
  color: lightgray;
}

.brandImage-dark-color {
  color: #626771;
}

.z-index-9 {
  z-index: 9 !important;
}

.d-flex-justify {
  display: flex;
  justify-content: space-between;
}

.mbsc-eventcalendar {
  height: auto !important;
  overflow: hidden;
}


//timeZone - setting page
.timezone .mbsc-material.mbsc-textfield-box.mbsc-select {
  // background: rgb(158, 158, 158) !important;
  background: rgba(var(--color-primary), 0.1);
  border-radius: var(--border-radius) !important;
}

.timezone .mbsc-material.mbsc-textfield-box.mbsc-select,
.timezone .mbsc-material.mbsc-textfield-inner-box {
  width: 232px;
}



.timezone .mbsc-ios.mbsc-textfield-box.mbsc-select {
  background: rgb(158, 158, 158) !important;
  border-radius: var(--border-radius) !important;
}

.timezone .mbsc-ios.mbsc-textfield-box.mbsc-select,
.timezone .mbsc-material.mbsc-textfield-inner-box {
  width: 232px;
}

// .timezone .mbsc-material.mbsc-textfield-box.mbsc-select.mbsc-focus, .mbsc-material.mbsc-textfield-inner-box.mbsc-focus {
//   background: transparent !important;
// }

.timezone .mbsc-focus .mbsc-material.mbsc-select-icon.mbsc-ltr {
  color: transparent !important;
}

.timezone .mbsc-select-icon-box {
  display: none;
}

.timezone .mbsc-material.mbsc-textfield {
  border-bottom-color: transparent !important;
}

.setting .grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.config-sidebar .sidebar {
  width: 280px !important;
}

@media (max-width: 400px) {
  .config-sidebar .sidebar {
    max-width: 80vw !important;
  }
}

.horizontal-icon .mat-icon {
  height: 2.8rem;
  width: 2.8rem;
}

.margin-right-0 {
  margin-right: 0px !important;
}

.preference-text-color {
  color: #000;
}

.vex-style-dark .preference-text-color {
  color: #fff;
}

.selected-preference {
  --tw-bg-opacity: 1;
  color: rgba(var(--color-primary), var(--tw-bg-opacity)) !important;
}

.bg-color-primary {
  --tw-bg-opacity: 1;
  color: rgba(var(--color-primary), var(--tw-bg-opacity));
}

.border-primary {
  --tw-bg-opacity: 1;
  color: rgba(var(--color-primary), var(--tw-bg-opacity));
}

.background-transparent {
  background: transparent !important;
}

:root .rounded-full {
  border-radius: var(--border-radius) !important;
}

.avatar-radius {
  border-radius: var(--border-radius) !important;
}



.vex-style-dark .text-contrast-black {
  color: #fff !important;
}

.Preference-border {
  border-bottom: 0px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.mat-vertical-stepper-header .mat-step-icon {
  border-radius: var(--border-radius) !important;
}

//popup
.mat-dialog-content {
  overflow: overlay !important;
}


//readonly textbox placeholder color is solid
.vex-style-dark .readOnly .mbsc-textfield {
  color: #ffffff !important;
}

.readOnly .mbsc-textfield {
  color: #000000 !important;
}



.event-calendar .mbsc-eventcalendar {
  border-radius: var(--border-radius) !important;
}


//sidebar header color
.active_status {
  background-color: rgba(76, 175, 80) !important;
}

.failed_status,
.InActive_status {
  background: rgba(244, 67, 54) !important;
}

.completed_status,
.Attention_status {
  background: rgba(242, 147, 84) !important;
}

.future_status {
  background: rgba(235, 235, 238) !important;
}


//only in the dark mode
.vex-style-dark {
  .active_status {
    background-color: rgba(76, 175, 80, 1.0) !important;
  }

  .failed_status,
  .InActive {
    background: rgba(244, 67, 54, 1.0) !important;
  }

  .completed_status,
  .Attention {
    background: rgba(242, 147, 84) !important;
  }

  .future_status {
    background: #11151e !important; // rgba(235, 235, 238, 0.5) !important;
  }
}

.delete:hover {
  color: red;
}

//order-receipt
//light mode
.vex-darkmode {
  background: white;
}

//dark mode
.vex-style-dark {
  .vex-darkmode {
    background: var(--background-base) !important;
  }

  .vex-header-darkmode {
    background-color: #000000 !important;
  }

  .vex--dark-header-color {
    color: rgba(255, 255, 255, 0.7) !important;
  }
}

.z-index-1 {
  z-index: 1;
}


.z-index-2 {
  z-index: 2;
}

.z-index-5 {
  z-index: 5 !important;
}

.get-started-hours .flex-end {
  justify-content: start !important;
}


.vex-style-dark .p-PaymentMethodSelector {
  background: rgba(255, 255, 255, 0.1019607843) !important;
}

.padding-0 {
  padding: 0px !important;
}


.mbsc-mobiscroll.mbsc-fr-center .mbsc-fr-w,
.mbsc-mobiscroll.mbsc-fr-bubble .mbsc-fr-w {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}

.mbsc-fr-popup {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
  border-radius: var(--border-radius) !important;
}

.td-width {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  // word-wrap: break-word;
}

.d-flex {
  display: flex !important;
}


.Tab--selected {
  border-color: var(--p-colorPrimary);
  color: #7e2b2b !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px rgb(var(--color-primary), 0.1);
}

//calender
.mbsc-material .mbsc-selected .mbsc-calendar-cell-text {
  background: rgba(var(--color-primary), 1) !important;
  border-color: rgba(var(--color-primary), 1) !important;
}

.mbsc-material.mbsc-range-day::after {
  background-color: rgba(var(--color-primary), 0.25) !important;
}

.mbsc-material.mbsc-popup-button.mbsc-font {
  color: rgba(var(--color-primary)) !important;
}

.mbsc-ios .mbsc-selected .mbsc-calendar-cell-text {
  background: rgba(var(--color-primary), 1) !important;
  border-color: rgba(var(--color-primary), 1) !important;
}

.mbsc-ios.mbsc-range-day::after {
  background-color: rgba(var(--color-primary), 0.25) !important;
}

.mbsc-ios.mbsc-popup-button.mbsc-font {
  color: rgba(var(--color-primary)) !important;
}

//end calender

.mbsc-mobiscroll.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
  background: rgba(var(--color-primary), 0.3) !important;
}

.repeat .mbsc-switch-primary {
  right: 2.25em;
}

.general-status .mbsc-icon {
  display: none;
}

.empty-w-full {
  max-width: 50% !important;
  max-height: 95% !important;
}

.empty-data .empty-data-box {
  height: calc(100vh - 155px) !important;
}

.empty-data {
  background: linear-gradient(135deg, var(--background-foreground) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px), linear-gradient(225deg, var(--background-foreground) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px) 0 64px;
  background-color: var(--background-foreground);
  background-size: 64px 128px;
}

@media only screen and (max-width: 500px) {
  .empty-w-full {
    max-width: 95% !important;
    max-height: 95% !important;
  }
}


.hover-cursor {
  cursor: pointer;
}

.mbsc-material.mbsc-popup-buttons {
  padding-right: 1em !important;
  padding-left: 1em !important;
}

// .mbsc-ios.mbsc-popup-buttons {
//   padding-right: 1em !important;
//   padding-left: 1em !important;
// }


//mat for suffix
.mat-form-field-flex {
  color: rgb(var(--color-primary)) !important;
}

input.mat-input-element {
  color: grey;
}


.image-text-5 {
  padding-right: 1.5rem;
}

@media only screen and (min-width: 600px) {
  .image-text-5 {
    padding-right: 0rem;
  }
}

.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  z-index: 1 !important;
}


//tab header sticky in side bar
.tab-fixed .mat-tab-header {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 60px !important;
  z-index: 99;
}

:root .sm\:gap-08rem {
  gap: 0.8rem;
}

.mbsc-np-btn .mbsc-np-btn-custom .mbsc-np-btn-custom-right .mbsc-fr-btn-e {
  font-size: 1.2rem !important;
}


//text-area css
.text-area-box .mbsc-textarea {
  min-height: 36px !important;
}

.text-area-box .mbsc-material.mbsc-textarea.mbsc-textfield-stacked,
.mbsc-material.mbsc-textarea.mbsc-textfield-floating {
  padding-top: 7px !important;
}

.text-area-box .mbsc-ios.mbsc-textarea.mbsc-textfield {
  background: transparent !important;
}

.text-area-box .mbsc-ios.mbsc-textarea-wrapper.mbsc-textfield-wrapper {
  margin-bottom: 1.5rem;
}

.text-area-box .mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-floating {
  padding-top: 0.625em !important;
  padding-bottom: 0 !important;
  border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
}

.text-area-box .mbsc-ios.mbsc-form-control-wrapper:before,
.mbsc-ios.mbsc-form-control-wrapper:after {
  border-color: transparent !important;
}

.text-area-box .mbsc-ios.mbsc-textarea.mbsc-textfield-floating {
  padding-top: 14px !important;
  padding-bottom: 8px;
}

.text-area-box .mbsc-material.mbsc-textarea-inner.mbsc-textfield-inner-floating {
  padding-top: 1.25em;
  ;
}

.text-area-box .mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-floating {
  padding-top: 1.25em;
  ;
}

//end text-area css

.category-class .mat-icon {
  cursor: pointer !important;
}

.text-align-right {
  text-align: right;
}

.alertCustomPopop .mbsc-padding {
  padding: 0px !important;
}

//vex-dark mode ios calender
.mbsc-ios-dark .mbsc-fr-w {
  background: #1a202e !important;
  color: #ffffff;
}

.mbsc-ios-dark.mbsc-popup-body {
  background: #1a202e !important;
  color: #ffffff;
}

.mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-select,
.mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-textfield-wrapper-underline,
.mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-textfield-box,
.mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-textfield-outline,
.mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-textarea-inner.mbsc-textfield-inner-box,
.mbsc-ios-dark.mbsc-popup .mbsc-ios-dark.mbsc-textarea-inner.mbsc-textfield-inner-outline {
  background: none;
}

.mbsc-ios-dark.mbsc-datepicker .mbsc-calendar,
.mbsc-ios-dark.mbsc-datepicker .mbsc-calendar-slide,
.mbsc-ios-dark.mbsc-datepicker .mbsc-calendar-cell {
  background: #1a202e !important;
}

//vex-dark mode ios calender

//border-radius-0
.inputBox-border-radius .mbsc-textfield-inner-box {
  border-radius: 0px !important;
}

.inputBox-border-radius .border-radius-0 {
  border-radius: 0px !important;
}

.inputBox-border-radius .mbsc-ios.mbsc-textfield {
  border-radius: 0px !important;
}


//only order page
.center-box-shadow .mbsc-fr-popup {
  // box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(223 220 220 / 50%) !important;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  background: rgba(0, 0, 0, 0.32) !important;
}

.center-box-shadow .mbsc-fr-scroll {
  background: rgba(0, 0, 0, 0.32) !important;
}






.vex-style-dark .categorymode {
  background: transparent !important;
  color: #fff !important;
}

.position-absolute {
  position: absolute;
}

.vex-style-dark .mbsc-material-dark .mbsc-fr-w {
  background: #1a202e !important;
  color: #fff;
}

.vex-style-dark .mbsc-ios-dark .mbsc-fr-w {
  background: #1a202e !important;
  color: #fff;
}

.vex-style-dark .content-sidebar .mat-tab-body-content {
  background: #1a202e !important;
  color: #fff;
}

.nubpad-phone .mbsc-fr-w {
  min-width: 320px !important;
}

.mbsc-ios.mbsc-select.mbsc-textfield-outline.mbsc-error,
.mbsc-ios.mbsc-textfield-box.mbsc-error {
  border-color: transparent !important;
}

.section-bottom {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.ios-section-bottom {
  padding-bottom: 10px !important;
}

.pd-top-6 {
  padding-top: 25px !important;
}

.mbsc-ios.mbsc-popup-top,
.mbsc-ios.mbsc-popup-bottom {
  border-color: transparent;
}

.mbsc-platform-ios .mbsc-fr-w {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.mbsc-ios .mbsc-popup-body.mbsc-popup-body-bottom {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.warning-text {
  --tw-text-opacity: 1;
  color: rgb(242 147 84 / var(--tw-text-opacity));
}

.mbsc-icon-remove {
  color: rgb(244, 67, 54);
}

.gotoTop.mat-fab .mat-button-wrapper {
  padding: 11px 0 !important;
}

.vex-style-dark .menus .mat-menu-item {
  background: transparent !important;
  color: white !important;
}

.vex-style-dark .menus .mat-menu-item .mat-icon-no-color,
.vex-style-dark .menus .mat-menu-submenu-icon {
  color: white !important;
}

:root .menus .mat-menu-item:hover:not([disabled]) .mat-icon {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
  color: rgba(var(--color-primary), var(--tw-text-opacity));
}

:root .-mt-20 {
  margin-top: -5rem !important;
}

.secondary-toolbar {
  background: var(--secondary-toolbar-background);
  height: var(--secondary-toolbar-height);
}

.borderListActive:hover {
  box-shadow: inset 5px 0px rgb(76 175 80);
  border: 2px solid rgb(76 175 80 / var(--tw-text-opacity));
}

.borderListInActive:hover {
  box-shadow: inset 5px 0px rgb(244 67 54);
}

.borderListAttention:hover {
  box-shadow: inset 5px 0px rgb(242 147 84);
}

.borderListCompleted:hover {
  box-shadow: inset 5px 0px rgb(255 152 0);
}

.borderListFailed:hover {
  box-shadow: inset 5px 0px rgb(244 67 54);
}

.listview-popup-padding .mbsc-popup-padding {
  padding: 0px !important;
}

.mbsc-material-dark .mbsc-lv-item {
  background: #1a202e !important;

}

.mbsc-material-dark .mbsc-lv-gr-title {
  background: #1a202e !important;
}


.mat-scroll .mat-drawer-content {
  overflow: overlay !important;
}

.mat-scroll .mat-drawer-content.empty-data-scroll {
  overflow: hidden !important;
}

.app-calenders .app-calender-right .mbsc-textfield-inner-box {
  // border-radius: 0px 8px 0 0;
  border-radius: 0 var(--border-radius) 0 0 !important;
}

.app-calenders .app-calender-left .mbsc-textfield-inner-box {
  // border-radius: 8px 0px 0 0;

  border-radius: var(--border-radius) 0 0 0 !important;
}

.app-calenders .app-calender-right .mbsc-ios.mbsc-textfield {
  // border-radius: 0px 8px 0 0 !important;
  border-radius: 0 var(--border-radius) 0 0 !important;
}

.app-calenders .app-calender-left .mbsc-ios.mbsc-textfield {
  border-radius: 8px 0px 0 0 !important;
  border-radius: var(--border-radius) 0 0 0 !important;

}

.label_text .mbsc-label {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 82% !important;
}


.mat-icon:hover {
  cursor: pointer;
}


//only for email
@media only screen and (max-width: 605px) {
  .flex-100 {
    flex: 1 1 100%;
  }
}

@media only screen and (min-width: 605px) {
  .flex-100 {
    flex: 1 1 0%;
  }
}

@media only screen and (max-width: 1200px) {
  .mail-image table {
    // display: block;
    // align-items: center;
    // justify-content: center;
    // display: flex;
    width: 100%;
  }
}

.vex-style-dark .mat-menu-item {
  background: transparent !important;
  color: white !important;
}

.vex-style-dark .mat-menu-item .mat-icon-no-color,
.vex-style-dark .mat-menu-submenu-icon {
  color: white !important;
}

.vex-style-dark .mat-menu-panel {
  background: #1a202e !important;
}

.mbsc-material-dark.mbsc-popup-arrow {
  background: #303030;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
}

.mbsc-material-dark .mbsc-fr-arr {
  background: #303030 !important;
}