@media (max-width: 1280px) {
    .mat-header-row .mat-table-sticky {
        top: 62px !important;
    }

    .header {
        position: sticky !important;
        top: -1px !important;
        z-index: 2;
    }
}

@media (min-width: 1280px) {
    .header {
        position: sticky !important;
        top: 0px !important;
        z-index: 2;
    }
}

.font-small {
    font-size: smaller !important;
}

// .mat-mini-fab-round {
//     border-radius: 0.5rem !important;
// }


tr.mat-header-row {
    height: 40px !important;
    background-color: #e2e2e2;
}

.vex-style-dark tr.mat-header-row {
    height: 40px !important;
    background-color: #000000;
}

tr.mat-row {
    height: 60px !important;
}

.imgSquer {
    height: 40px !important;
    min-width: 40px !important;
    border-radius: 0.5rem !important;
}

.vex-page-layout-header {
    background-color: transparent !important
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
    padding-left: 15px;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
    padding-right: 15px;
}


//add side bar table
.mat-tab-body.mat-tab-body-active {
    position: relative;
    overflow-x: overlay !important;
    overflow-y: overlay !important;
    z-index: 1;
    flex-grow: 1;
}

.mat-sort-header.mat-header-cell {
    top: 0px !important;
}

//add side bar table
th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
    padding: 0px !important;
    padding-left: 10px !important;
}

.mat-footer-cell {
    padding-left: 12px !important;
}


table tfoot {
    position: sticky;
}


// table {
//   width: 100%;
// }

@media only screen and (min-width: 1280px) {
    .table-container {
        max-height: calc(100vh - 208px);
        max-width: 100%;
        overflow: overlay;
        border-radius: 0px 0px var(--border-radius) var(--border-radius);
    }
}

@media only screen and (max-width: 1280px) {
    .table-container {
        // height: calc(100vh - 280px);
        // max-width: 100%;
        // overflow: overlay;
    }
}

@media only screen and (min-width: 1280px) {
    .table-container-disable-footer {
        max-height: calc(100vh - 150px);
        max-width: 100%;
        overflow: overlay;
        border-radius: 0px 0px var(--border-radius) var(--border-radius);
    }
}

@media only screen and (max-width: 1280px) {
    .table-container-disable-footer {
        // height: calc(100vh - 223px);
        // max-width: 100%;
        // overflow: overlay;

        max-height: calc(100vh - 150px);
        max-width: 100%;
        overflow: overlay;
        border-radius: 0px 0px var(--border-radius) var(--border-radius);
    }
}

.scroll-y {
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    height: calc(100vh - 206px);
    max-width: 100%;
}

.scroll-x {
    overflow-y: hidden;
    overflow-x: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    height: calc(100vh - 206px);
    max-width: 100%;
}


tr.mat-footer-row {
    height: 40px !important;
    background-color: #e2e2e2;
    font-weight: 550;
}

.table-scroll {
    // height: calc(100vh - 155px) !important;
    max-width: 100% !important;
    overflow: overlay !important;
}



.is-mobile .dashboard h2 {
    font: 400 45px/48px Inter var, Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple ColorDef Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto ColorDef Emoji";
    font: 400 45px / 48px var(--font);
    letter-spacing: -.005em;
    padding-left: 0px !important;
}

.display-flex-center {
    display: flex;
    justify-content: center;
}

.align-item-center {
    align-items: center !important;
}

.justify-end {
    width: 100%;
    justify-content: end;
}

.graphs-grid-main .demo-chart div:not(.class) {
    width: auto !IMPORTANT;
}

.graphs-grid-main {
    position: relative;
    overflow: hidden;
}


.graphs-grid-main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    // padding: 20px 15px;
    padding: 0px 0px 0px 0px;


    .demo-chart {
        background: #fff;
        border-radius: 15px;
    }
}

.vex-style-dark .graphs-grid-main {
    .demo-chart {
        background: transparent !important;
        border-radius: 15px;
    }
}


@media (max-width:767px) {
    .graphs-grid-main {
        grid-template-columns: repeat(1, 1fr);
    }
}

.chartTable tr.mat-row {
    height: 57px !important;
}

// th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type{
//     padding-left: 10px !important;
// }

.mat-scroll .header {
    z-index: 111 !important;
}

.mat-column-groupHeader {
    background-color: #e2e2e2;
    font-weight: 550;
}

.mat-row.group_container {
    height: 40px !important;
    background-color: #e2e2e2;
    font-weight: 550;
}


//for only report - group by area
.groupBy .mat-drawer-container {
    background-color: transparent !important;
}

.groupBy .card {
    background-color: transparent !important;
}

.groupBy .bg-foreground {
    background-color: transparent !important;
}

.groupBy .bg-base {
    background-color: transparent !important;
}

.groupBy .mat-elevation-z8 {
    box-shadow: none !important;
}


.bg-foreground .searchtxt {
    --tw-bg-opacity: 1;
    background-color: rgba(var(--background-foreground-rgb), var(--tw-bg-opacity)) !important;
}

//end
