.mat-step-text-label {
    font-size: 16px !important;
}

.mat-step-label-selected .mat-step-text-label {
    font-weight: 500 !important;
}

.footer-button {
    display: flex;
    justify-content: space-between;
}

.scroll {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

//simple vertical

.mat-vertical-content {
    padding: 0 0px 15px 15px !important;
}

.get-started-bottom .mat-vertical-content {
    padding: 0 15px 15px 15px !important;
}

//only popup vertical
.dialog-container .mat-vertical-content {
    padding: 0 0px 15px 15px !important;
}


//popup padding
.mat-dialog-container {
    padding-bottom: 0px !important;
}

:root .p-gutter {
    padding-top: 0 !important;
}


.mat-tab-body-content {
    overflow: overlay !important;
}