.event-calendar {
  tr.mat-header-row {
    height: 40px;
    background-color: #e2e2e2;
  }

  .hide_element {
    visibility: hidden;
  }

  .bg-color {
    background-color: green;
  }

  .calender-height {
    // height: calc(101vh - 133px);
    height: calc(100vh - 135px);
    overflow: overlay;
    border-radius: var(--border-radius) !important;
  }

  ::ng-deep.mat-tab-body:nth-child(2) {
    padding-bottom: 0 !important;
  }

  /* width */
  ::-webkit-scrollbar {
    // width: 1px !important;
    // overflow: overlay !important;
  }

  ::ng-deeep body.is-blinkbody.is-blink .calender-height::-webkit-scrollbar-thumb {
    border: none !important;
    box-shadow: none !important;
  }

  .mbsc-event-list {
    overflow-y: overlay !important;
  }
}

.event-calendar .mbsc-eventcalendar {
  // border-radius: 10px !important;
  border-radius: var(--border-radius) !important;
}

.billing {
  .mbsc-event-start {
    visibility: hidden !important;
  }

  .mbsc-event-all-day {
    visibility: hidden !important;
  }
}

.mbsc-calendar-wrapper.mbsc-calendar-wrapper-fixed {
  width: 100% !important;
}


//onlly profile calender page
.profile .calender-height {
  height: auto;
}

.calender-popup .mbsc-popup-padding {
  padding-bottom: 0 !important;
  padding: 0;
}




.calender-margin-bottom-0 .mbsc-ios.mbsc-textfield-wrapper-box {
  margin-bottom: 0px !important;
}

.calender-margin-bottom-0 .mbsc-material.mbsc-textfield-wrapper-box {
  margin-bottom: 0px !important;
}


.calender-margin-bottom-0 .mbsc-material.mbsc-textfield-inner-box {
  background: transparent !important;
}

.calender-margin-bottom-0 .mbsc-material.mbsc-textfield-box.mbsc-select {
  background: transparent !important;
}

.calender-margin-bottom-0 .mbsc-material.mbsc-textfield {
  border-bottom: 0px !important;
}


.calender-margin-bottom-0 .mbsc-ios.mbsc-textfield-inner-box {
  background: transparent !important;
  color: rgb(var(--color-primary)) !important;
}

.calender-margin-bottom-0 .mbsc-ios.mbsc-textfield-box.mbsc-select {
  background: transparent !important;
  color: rgb(var(--color-primary)) !important;
}

.calender-margin-bottom-0 .mbsc-ios.mbsc-textfield {
  border-bottom: 0px !important;
}

.calender-margin-bottom-0 .mbsc-ios.mbsc-label,
.mbsc-ios.mbsc-textfield {
  color: rgb(var(--color-primary)) !important;
}

.calender-margin-bottom-0 .mbsc-ios.mbsc-textfield-icon, .mbsc-ios.mbsc-select-icon {
  color: rgb(var(--color-primary)) !important;
}