// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";
@import "assets/css/search.scss";

:root {
  --font: 'Poppins', sans-serif;
  --my-primary: red;
}

:root {}

@import "~@mobiscroll/angular/dist/css/mobiscroll.scss";
$mbsc-font-path: "~@mobiscroll/angular4/dist/css/"; // in case of v4 the font-path needs adjusting to point to the right directory
@import "~@mobiscroll/angular4/dist/css/mobiscroll.scss";
@import "assets/css/sideBar.scss";
@import "assets/css/wizard.scss";
@import "assets/css/common.scss";
@import "assets/css/common-page.scss";
@import "assets/css/calender.scss";
@import "assets/css/table-page.scss";
@import "assets/css/mobiscroll_custom.scss";
@import "assets/css/chart.scss";
@import "assets/css/layout.scss";




::ng-deep .specific-class>.mat-expansion-indicator:after {
  color: white;
}

.popover {
  z-index: 999 !important;
}

.img-height {
  min-height: 3.5rem !important;
}

.input-width {
  width: 20% !important;
}

.calender-height::-webkit-scrollbar {
  width: 0px !important;
}

body.is-blinkbody.is-blink .calender-height::-webkit-scrollbar-thumb {
  border: none !important;
  box-shadow: none !important;
}

.calender-height .mbsc-event-list {
  padding: 0 !important;
}

.fixed-name {
  position: sticky !important;
  top: 0px !important;
  z-index: 999 !important;
  background: white;
}

.center-item {
  display: flex !important;
  align-items: center !important;
}

.mbsc-fr-overlay {
  background: none !important;
}

// .mbsc-fr-bubble-bottom {
//   box-shadow: 0px 0px 23px #cccc !important;
// }


// .mat-dialog-container {

//   display: flex !important; 
//   flex-direction: column !important;
//   justify-content: center !important;
//   align-items: center !important;
//   text-align: center !important;
//   position: fixed !important;
//   z-index: 1050 !important;
//   top: 0 !important;
//   left: 0 !important;
//   width: 100% !important;
//   height: 100vh !important;
//   pointer-events: none !important;
//   transition: opacity 0.25s !important;

// }

.fixed-table-header {
  position: fixed !important;
  top: 0 !important;
  left: 13px !important;
  right: 13px !important;
  z-index: 999 !important;
  -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

}

.fixed-row {
  position: fixed !important;
  top: 60px !important;
  left: 13px !important;
  right: 13px !important;
  z-index: 999 !important;
  width: 100% !important;
  -webkit-animation: slide-top-1 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-top-1 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.fixed-row-top {
  position: fixed !important;
  top: 0 !important;
  left: 13px !important;
  right: 13px !important;
  z-index: 999 !important;
  width: 100% !important;
  // -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  // animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2022-11-8 17:54:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
/* ----------------------------------------------
 * Generated by Animista on 2022-11-8 18:15:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-4px);
  }
}






@-webkit-keyframes slide-top-1 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes slide-top-1 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(-4px);
  }
}

.integrationPopop .mat-tab-body-content {
  overflow-x: hidden !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.integration-textbox .mbsc-material.mbsc-switch-right {
  padding: 1.5em 4em 0.9375em 1em !important;
}

.integrationPopop .mat-tab-body-wrapper{
  margin-top: 1rem;
}

.integrationPopop .mat-accordion>.mat-expansion-panel-spacing:first-child, .mat-accordion>*:first-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing{
  margin-top: 2px;
}

// .mbsc-material.mbsc-textfield-icon,
// .mbsc-material.mbsc-select-icon {
//   color: rgb(59 130 246 / 0.5) !important;
// }

// .vex-style-dark {
//   .mbsc-ios.mbsc-event-day.mbsc-list-header {
//     background: #1a202e !important;
//     color: #fff;
//     border-color: #1a202e !important;
//   }

//   .mbsc-ios.mbsc-calendar-wrapper {
//     border-color: #1a202e !important;
//   }

//   .mbsc-ios.mbsc-calendar-controls .md-outlook-calendar-buttons mat-icon.mat-icon {
//     fill: #fff !important;
//   }

//   .mbsc-ios.mbsc-event.mbsc-list-item {
//     background: #11151e;
//     color: #ffff;
//   }

//   .mbsc-material.mbsc-popup-body {
//     background: #1a202e;
//     color: #fff;
//   }

//   .mbsc-material.mbsc-textfield-tag {
//     background: #1a202e;
//   }

//   .mbsc-mobiscroll.mbsc-fr-center .mbsc-fr-w,
//   .mbsc-mobiscroll.mbsc-fr-bubble .mbsc-fr-w {
//     background: #1a202e !important;
//   }

//   .mbsc-mobiscroll .mbsc-fr-arr {
//     background: #1a202e;
//   }

//   .mbsc-mobiscroll .mbsc-fr-w {
//     background: #ffffff !important;
//     color: #fff;
//   }

//   input:-webkit-autofill,
//   input:-webkit-autofill:hover,
//   input:-webkit-autofill:focus,
//   input:-webkit-autofill:active {
//     -webkit-box-shadow: 0 0 0 30px #313743 inset !important;
//   }

//   /*Change text in autofill textbox*/
//   input:-webkit-autofill {
//     -webkit-text-fill-color: #ffffff !important;
//   }

//   .mbsc-focus+.remove-icon-style {
//     position: absolute;
//     right: 30px;
//     color: rgb(59 130 246 / 0.5);
//     margin-top: -1rem;
//   }
// }
.vex-style-dark {

  .mat-tree-node[_ngcontent-ppr-c720],
  .mat-nested-tree-node[_ngcontent-ppr-c720] {
    color: var(--background-app-bar) !important;
    background-color: var(--background-app-bar) !important;
  }

  .mat-tree {
    background: #1a202e !important;
  }

  .mat-tree-node {
    background: transparent !important;
    color: #fff !important;
  }

  // .mbsc-textfield-inner {
  //   background: none !important;
  // }

  .mbsc-textfield-ripple {
    background-color: inherit !important;
  }

  // .mbsc-mobiscroll.mbsc-fr-center .mbsc-fr-w,
  // .mbsc-mobiscroll.mbsc-fr-bubble .mbsc-fr-w {
  //   background: #1a202e !important;
  // }

  // .mbsc-mobiscroll .mbsc-fr-w {
  //   background: #ffffff !important;
  //   color: #fff;
  // }

  // .mbsc-mobiscroll .mbsc-fr-arr {
  //   background: #1a202e;
  // }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #313743 inset !important;
  }

  /*Change text in autofill textbox*/
  input:-webkit-autofill {
    -webkit-text-fill-color: #ffffff !important;

  }
}

.mbsc-material.mbsc-textfield-ripple {
  background-color: inherit !important;
}